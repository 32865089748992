import { SET_SETUP_PREFERENCES_PAGE_SEEN } from 'actions/constants'

export default (
  state = {
    preferencesPageSeen: false,
  },
  { type, payload },
) => {
  switch (type) {
    case SET_SETUP_PREFERENCES_PAGE_SEEN:
      return Object.assign({}, state, {
        preferencesPageSeen: payload,
      })
    default:
      return state
  }
}
