import { Election } from 'types'
import { isInPast } from 'utils/isInPast'
import appConfig from '../appConfig.json'

export const shouldSeeVoterGuide = (election: Election | null | undefined) => {
  return Boolean(
    (election?.vg_publish_at && isInPast(election.vg_publish_at)) ||
      appConfig.showPrePublishedVoterGuide,
  )
}
