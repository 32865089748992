import React from 'react'
import classNames from 'classnames'
import PlaceholderLogo from 'assets/images/logo.png'
import './Logo.scss'

interface Props {
  alt?: string
  className?: string
  src?: string | null
}
const Logo: React.FC<Props> = ({ alt, className, src }) => (
  <img
    alt={`${alt} Logo`}
    className={classNames('Logo', className)}
    src={src || PlaceholderLogo}
  />
)

export default Logo
