import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { faHexagon } from '@fortawesome/pro-solid-svg-icons'

import { BarAssociationEvaluation, Candidate } from 'types'

import { DataPoints, Hexagon } from 'components/sparkles'
import styles from './barAssociationEvaluations.module.scss'

interface BarAssociationProps {
  candidate: Candidate
}

const NOT_QUALIFIED = '0'
const QUALIFIED = '1'
const HIGHLY_QUALIFIED = '2'
const NOT_EVALUATED = '3'

const groupBy = (arr: any[], key: string | number) =>
  arr.reduce((acc, x) => {
    ;(acc[x[key]] = acc[x[key]] || []).push(x)
    return acc
  }, {})

const BarAssociationEvaluations: React.FC<BarAssociationProps> = ({
  candidate,
}) => {
  const { t } = useTranslation()

  const groupedEvals = groupBy(
    candidate.bar_association_evaluations,
    'evaluation',
  )

  const notQualifiedCount =
    (groupedEvals[NOT_QUALIFIED] && groupedEvals[NOT_QUALIFIED].length) || 0
  const qualifiedCount =
    (groupedEvals[QUALIFIED] && groupedEvals[QUALIFIED].length) || 0
  const highlyQualifiedCount =
    (groupedEvals[HIGHLY_QUALIFIED] && groupedEvals[HIGHLY_QUALIFIED].length) ||
    0
  const declinedCount =
    (groupedEvals[NOT_EVALUATED] && groupedEvals[NOT_EVALUATED].length) || 0

  // If none of the bar associations uses the 'highly qualified' predicate we'll hide it from UI
  const hasHighlyQualified = highlyQualifiedCount !== 0

  return (
    <section
      data-testid="barassociation-section"
      className={styles.barAssociationEvaluations}
    >
      <div className={styles.totals}>
        {hasHighlyQualified && (
          <div className={classNames(styles.total, styles.highlyQualified)}>
            {t(
              'sparkles_main:CandidateDetails.BarAssociationEvaluations.highlyQualified',
            )}
            <Hexagon
              className={classNames(
                styles.hexagon,
                styles.highlyQualifiedHexagon,
              )}
            >
              {highlyQualifiedCount}
            </Hexagon>
          </div>
        )}
        <div className={classNames(styles.total, styles.qualified)}>
          {t(
            'sparkles_main:CandidateDetails.BarAssociationEvaluations.qualified',
          )}
          <Hexagon
            className={classNames(styles.hexagon, styles.qualifiedHexagon)}
          >
            {qualifiedCount}
          </Hexagon>
        </div>
        <div className={classNames(styles.total, styles.notQualified)}>
          {t(
            'sparkles_main:CandidateDetails.BarAssociationEvaluations.notQualified',
          )}
          <Hexagon
            className={classNames(styles.hexagon, styles.notQualifiedHexagon)}
          >
            {notQualifiedCount}
          </Hexagon>
        </div>
        {declinedCount > 0 && (
          <div className={classNames(styles.total, styles.notEvaluated)}>
            {t(
              'sparkles_main:CandidateDetails.BarAssociationEvaluations.notEvaluated',
            )}
            <Hexagon
              className={classNames(styles.hexagon, styles.notEvaluatedHexagon)}
            >
              {declinedCount}
            </Hexagon>
          </div>
        )}
      </div>

      <DataPoints
        className={styles.highlyQualified}
        formatter={(item: BarAssociationEvaluation) =>
          item.bar_association_name
        }
        icon={faHexagon}
        items={groupedEvals[HIGHLY_QUALIFIED]}
        title={t(
          'sparkles_main:CandidateDetails.BarAssociationEvaluations.highlyQualified',
        )}
      />

      <DataPoints
        className={styles.qualified}
        formatter={(item: BarAssociationEvaluation) =>
          item.bar_association_name
        }
        icon={faHexagon}
        items={groupedEvals[QUALIFIED]}
        title={t(
          'sparkles_main:CandidateDetails.BarAssociationEvaluations.qualified',
        )}
      />

      <DataPoints
        className={styles.notQualified}
        formatter={(item: BarAssociationEvaluation) =>
          item.bar_association_name
        }
        icon={faHexagon}
        items={groupedEvals[NOT_QUALIFIED]}
        title={t(
          'sparkles_main:CandidateDetails.BarAssociationEvaluations.notQualified',
        )}
      />

      <DataPoints
        className={styles.notEvaluated}
        formatter={(item: BarAssociationEvaluation) =>
          item.bar_association_name
        }
        icon={faHexagon}
        items={groupedEvals[NOT_EVALUATED]}
        title={t(
          'sparkles_main:CandidateDetails.BarAssociationEvaluations.notEvaluated',
        )}
      />
    </section>
  )
}

export default BarAssociationEvaluations
