import { connect } from 'react-redux'

import toggleLeadBar from 'actions/toggleLeadBar'
import { StoreState } from 'types'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import saveBallot from 'actions/saveBallot'

const mapStateToProps = ({
  appConfig: {
    featureFlags,
    logoUrl,
    enableLeadBar,
    tenant: { name },
    universe: { logoLinkUrl },
  },
  ballot,
  browser,
  election,
  lead,
  showLeadBar,
}: StoreState) => ({
  ballot,
  election,
  enableSearch: featureFlags.includes('ELECTION_CENTER_SEARCH'),
  isSmallScreen: browser.lessThan.medium,
  lead,
  logoLinkUrl,
  logoUrl,
  showLeadBar: enableLeadBar && showLeadBar && Boolean(ballot),
  tenantName: name,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, null, AnyAction>,
) => ({
  onLeadBarClose: () => dispatch(toggleLeadBar(false)),
  onLocaleChange: (newLocale: string) =>
    dispatch(saveBallot({ locale: newLocale })),
})

export default connect(mapStateToProps, mapDispatchToProps)
