import * as types from './constants'
import { History } from 'history'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { StoreState } from 'types'
import qs from 'qs'
import { AnalyticsContextType } from 'contexts/AnalyticsContext/AnalyticsContext'
import getPathForApp from 'utils/getPathForApp'
import fetchBallotElection from './fetchBallotElection'
import toggleElectionAvailable from './toggleElectionAvailable'
import clearVotingPlan from './clearVotingPlan'
import fetchBallot from './fetchBallot'
import fetchChoiceElections from './fetchChoiceElections'

const tick =
  (
    history: History,
    redirect: boolean,
    maxTries: number,
    analytics?: AnalyticsContextType,
  ): ThunkAction<void, StoreState, null, AnyAction> =>
  async (dispatch, getState) => {
    const { pingCount } = getState()

    if (pingCount === maxTries) {
      dispatch(reset())
    } else {
      dispatch({ type: types.PING_BALLOT })
    }

    await dispatch(fetchBallot())
    const {
      appConfig: { enableDashboard, enableElectionCenter, homePath },
      ballot,
    } = getState()

    if (ballot?.processed && ballot?.polling_places_processed) {
      if (
        ballot.election_ids &&
        ballot.election_ids.length > 0 &&
        !ballot.election_selected
      ) {
        await dispatch(fetchChoiceElections(ballot.election_ids))
      }
      await dispatch(fetchBallotElection())
      await dispatch(clearVotingPlan())

      if (analytics) {
        await analytics.track('Ballot processing completed', {
          address: ballot.address,
          street_number: ballot.street_number,
          street: ballot.street,
          city: ballot.city,
          state: ballot.state,
          zipcode: ballot.zipcode,
          first_name: ballot.first_name,
          last_name: ballot.last_name,
          locale: ballot.locale,
          phone: ballot.phone,
          email: ballot.email,
          notifications_opt_in: ballot.notifications_opt_in,
        })
      }

      dispatch(reset())

      if (redirect) {
        const { destination } = qs.parse(history?.location?.search, {
          ignoreQueryPrefix: true,
        })

        if (destination && getPathForApp(destination) !== '/') {
          history.push(getPathForApp(destination))
        } else if (
          !enableElectionCenter &&
          homePath === '/check_registration'
        ) {
          // election center is not enabled and the user does not have a ballot
          history.push(`${homePath}`)
        } else if (
          history.location.pathname.includes('request_ballot') ||
          history.location.pathname.includes('check_registration') ||
          history.location.pathname.includes('register')
        ) {
        } else if (!enableElectionCenter && ballot && !ballot.election_id) {
          dispatch(toggleElectionAvailable(false))
          history.push(getPathForApp('no_elections'))
        } else if (ballot.election_id) {
          history.push(`${homePath}?load=1`)
        } else if (enableDashboard) {
          history.push(enableDashboard ? getPathForApp('dashboard') : '/')
        } else {
          // Pass section/completed params to election center
          const { completed, section } = qs.parse(history.location.search, {
            ignoreQueryPrefix: true,
          })

          const params = qs.stringify(
            { completed, section },
            { skipNulls: true },
          )

          history.push(
            enableElectionCenter
              ? `${getPathForApp('election_center')}${params && `?${params}`}`
              : '/',
          )
        }
      }
    } else {
      setTimeout(
        () => dispatch(tick(history, redirect, maxTries, analytics)),
        500 * Math.pow(2, pingCount + 1),
      )
    }
  }

const reset = () => ({ type: types.PING_BALLOT_STOP })

export default (
    history: History,
    redirect: true,
    maxTries: 5,
    analytics?: AnalyticsContextType,
  ): ThunkAction<void, StoreState, null, AnyAction> =>
  dispatch => {
    setTimeout(
      () => dispatch(tick(history, redirect, maxTries, analytics)),
      500,
    )
  }
