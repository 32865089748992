import React from 'react'
import { CandidateMarkdown } from 'types'
import { NewsItem } from '../../atoms'
import styles from './newsItemList.module.scss'

interface NewsItemProps {
  newsItems: CandidateMarkdown[]
}

const NewsItemList: React.FC<NewsItemProps> = ({ newsItems }) => {
  return (
    <section className={styles.newsContainer} data-testid="news-section">
      {newsItems.map((newsItem: CandidateMarkdown, index) => (
        <NewsItem newsItem={newsItem.metadata_blob} key={index} />
      ))}
    </section>
  )
}

export default NewsItemList
