import React from 'react'
import { Candidate } from 'types'
import { useTranslation } from 'react-i18next'
import { IssueCategory } from 'types'
import extractBlob from 'utils/extractBlob'
import { groupEndorsements } from 'utils/stanceAndEndorsementUtils'
import {
  BarAssociationEvaluations,
  Bio,
  CheckList,
  IssueSortedItems,
  NewsItemList,
} from '../../molecules'
import { Markdown, Panel } from '../../atoms'
import styles from './candidateDetails.module.scss'
import { findNewsLinkItems } from 'utils/newsMetadataUtils'

export interface CandidateDetailProps {
  candidate: Candidate
  candidateSectionOrder?: string[]
  issueIdWhitelist: number[] | null
  onEndorsementClick(issue: IssueCategory): void
  onIssueClick(issue: IssueCategory): void
  onQuestionClick(issue: IssueCategory): void
  showBarAssociationEvaluations: boolean
  showCivicEngineBio: boolean
  showEndorsements: boolean
  showIssues: boolean
  showIssueQuotationMarks: boolean
  showQuestions: boolean
  showVotingHistory: boolean
}

const CandidateDetails: React.FC<CandidateDetailProps> = ({
  candidate,
  candidateSectionOrder = [
    'bar_association_evaluations',
    'bio',
    'questionnaire',
    'issues',
    'endorsements',
    'voting_history',
  ],
  issueIdWhitelist,
  onEndorsementClick,
  onIssueClick,
  onQuestionClick,
  showBarAssociationEvaluations = true,
  showCivicEngineBio = true,
  showEndorsements = true,
  showIssues = true,
  showIssueQuotationMarks,
  showQuestions = true,
  showVotingHistory,
}) => {
  const { i18n, t } = useTranslation()
  const stancesBlob = extractBlob(candidate.markdowns, 'stances', i18n.language)
  const endorsementsBlob = extractBlob(candidate.markdowns, 'endorsements')
  const newsItems = findNewsLinkItems(candidate)

  const sections = [
    {
      key: 'J',
      label: 'bar_association_evaluations',
      titleKey: 'BarAssociationEvaluations.title',
      component: showBarAssociationEvaluations &&
        candidate.bar_association_evaluations &&
        candidate.bar_association_evaluations.length > 0 && (
          <BarAssociationEvaluations candidate={candidate} key="J" />
        ),
    },
    {
      key: 'B',
      label: 'bio',
      titleKey: 'Bio.title',
      component: (
        <Bio
          candidate={candidate}
          key="B"
          showCivicEngineBio={showCivicEngineBio}
        />
      ),
    },
    {
      key: 'Q',
      titleKey: 'questionnaireTitle',
      label: 'questionnaire',
      component: showQuestions ? (
        <IssueSortedItems
          description={t(
            'sparkles_main:CandidateDetails.questionnaireDescription',
            undefined,
          )}
          items={candidate.issues
            .filter(i => i.is_question)
            .map(issue => {
              return { ...issue, items: issue.stances }
            })}
          key="Q"
          onIssueClick={onQuestionClick}
          placeholder={t(
            'sparkles_main:CandidateDetails.questionnairePlaceholder',
          )}
          title={t('sparkles_main:CandidateDetails.questionnaireTitle')}
        />
      ) : null,
    },
    {
      key: 'I',
      label: 'issues',
      titleKey: 'issuesTitle',
      component:
        showIssues && candidate.issues.length > 0 ? (
          <IssueSortedItems
            blob={stancesBlob}
            description={t(
              'sparkles_main:CandidateDetails.issuesDescription',
              undefined,
            )}
            items={candidate.issues
              .filter(
                i =>
                  !i.is_question &&
                  (!issueIdWhitelist || issueIdWhitelist.includes(i.id)),
              )
              .map(issue => {
                return { ...issue, items: issue.stances }
              })}
            key="I"
            onIssueClick={onIssueClick}
            placeholder={t('sparkles_main:CandidateDetails.issuesPlaceholder')}
            showQuotationMarks={showIssueQuotationMarks}
            title={t('sparkles_main:CandidateDetails.issuesTitle')}
          />
        ) : null,
    },
    {
      key: 'E',
      label: 'endorsements',
      titleKey: 'Endorsements.title',
      component:
        showEndorsements && candidate.endorsements.length > 0 ? (
          <section key="E">
            <IssueSortedItems
              items={groupEndorsements(candidate.endorsements)}
              onIssueClick={onEndorsementClick}
              placeholder={t(
                'sparkles_main:CandidateDetails.Endorsements.placeholder',
              )}
              title={t('sparkles_main:CandidateDetails.Endorsements.title')}
            />
            {endorsementsBlob && (
              <section>
                <h3>
                  {t(
                    'sparkles_main:CandidateDetails.MarkdownEndorsements.title',
                  )}
                </h3>
                <Panel
                  isButton={false}
                  className={styles.candidateDetails__MarkdownBlob}
                >
                  <Markdown source={endorsementsBlob} />
                </Panel>
              </section>
            )}
          </section>
        ) : null,
    },
    {
      key: 'V',
      label: 'voting_history',
      titleKey: 'votingHistoryTitle',
      component:
        showVotingHistory &&
        candidate.check_or_x &&
        candidate.check_or_x.length > 0 ? (
          <CheckList
            items={candidate.check_or_x}
            key="V"
            title={t('CandidateDetails.votingHistoryTitle')}
          />
        ) : null,
    },
    {
      key: 'N',
      label: 'news',
      titleKey: 'newsTitle',
      component:
        newsItems.length > 0 ? (
          <NewsItemList newsItems={newsItems} key="N" />
        ) : null,
    },
  ]

  return (
    <section
      data-testid="candidate-details"
      className={styles.candidateDetails}
    >
      {sections
        .slice()
        .sort(
          (a, b) =>
            candidateSectionOrder.indexOf(a.label) -
            candidateSectionOrder.indexOf(b.label),
        )
        .map(s => {
          if (!!s.component) {
            return (
              <div className={styles.candidateDetailsSection}>
                <h2 className={styles.sectionTitle}>
                  {t(`sparkles_main:CandidateDetails.${s.titleKey}`)}
                </h2>
                <div>{s.component}</div>
              </div>
            )
          } else {
            return null
          }
        })}
    </section>
  )
}

export default CandidateDetails
