import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { BarAssociationEvaluation, Education, Experience } from 'types'
import { Markdown, SourcedSnippet } from '../../atoms'

import styles from './dataPoints.module.scss'

export interface DataPointsProps {
  className?: string
  formatter(item: Education | Experience | BarAssociationEvaluation): string
  icon: IconDefinition
  items: (Education | Experience | BarAssociationEvaluation)[]
  title: string
}

const DataPoints: React.FC<DataPointsProps> = ({
  className,
  formatter,
  icon,
  items,
  title,
}) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <section
      data-testid="datapoint"
      className={classNames(styles.container, className)}
    >
      <h4 className={styles.title}>
        {icon && <FontAwesomeIcon className={styles.icon} icon={icon} />}
        {title}
      </h4>
      <ul className={styles.list}>
        {items.map((item, key) => (
          <li className={styles.item} key={key}>
            {'source_url' in item ? (
              <SourcedSnippet
                className={styles.snippet}
                snippet={formatter(item)}
                sourceUrl={item.source_url}
              />
            ) : (
              <Markdown source={formatter(item)} />
            )}
          </li>
        ))}
      </ul>
    </section>
  )
}

export default DataPoints
