import { AnyAction } from 'redux'
import * as types from '../actions/constants'

export default (state = null, action: AnyAction): boolean | null => {
  switch (action.type) {
    case types.TOGGLE_CONTRIBUTE_PROMPT:
      return action.value
    default:
      return state
  }
}
