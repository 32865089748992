import * as Sentry from '@sentry/react'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import qs from 'qs'
import { responsiveStoreEnhancer } from 'redux-responsive'
import Cookies from 'js-cookie'
import createApiMiddleware from './createMiddleware'
import { SAVE_BALLOT_PARAMS } from 'actions/constants'
import saveBallotParams from 'actions/saveBallotParams'
import appConfig from './appConfig'
import rootReducer from './reducer'
import usStates from './data/usStates'
import setIsTimeTraveling from 'actions/setIsTimeTraveling'
import setSetupPreferencesPageSeen from 'actions/setSetupPreferencesPageSeen'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (action.type === SAVE_BALLOT_PARAMS) {
      return {
        ...action,
        token: null,
      }
    }

    return action
  },
  stateTransformer: state => {
    // Transform the state to remove sensitive information
    const transformedState = {
      ...state,
      auth: {
        ...state.auth,
        user: {
          ...state.auth.user,
          engine_token: '<FILTERED>',
        },
      },
    }

    return transformedState
  },
})

const createMystore = (customAppConfig = {}) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  // Grab the state from a global variable injected into the server-generated HTML
  const preloadedState = window.__PRELOADED_STATE__

  // Allow the passed state to be garbage-collected
  delete window.__PRELOADED_STATE__
  const middleware = [
    createApiMiddleware(
      window.btoa,
      process.env.REACT_APP_API_URL,
      process.env.REACT_APP_API_KEY,
      {
        authPasswordPath: 'auth.user.engine_token',
        authUsernamePath: 'auth.user.ballot_id',
        debug: !!appConfig.debug,
        allowTimeTravel: !!appConfig.allowTimeTravel,
      },
    ),
    thunk,
  ]

  const initialState = {
    appConfig: Object.assign({}, appConfig, customAppConfig),
    usStates,
  }

  const store = createStore(
    rootReducer(appConfig),
    preloadedState || initialState,
    composeEnhancers(
      responsiveStoreEnhancer,
      applyMiddleware(...middleware),
      sentryReduxEnhancer,
    ),
  )

  // Read values from cookie
  const cookie = Cookies.getJSON('ce_s') || {}

  // Read values from URL params
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

  if (appConfig.allowTimeTravel) {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    if (params.at) {
      store.dispatch(setIsTimeTraveling(true))
    }
  }

  // Users signed in with cookie must be signed out if URL params differ from
  // params in cookie
  const mustBeSignedOut =
    (params.bid && cookie.bid && params.bid !== cookie.bid) ||
    (params.et && cookie.et && params.et !== cookie.et)

  const ballotId = params.bid || cookie.bid || null
  const ballotUserId = mustBeSignedOut || !cookie.uid ? null : cookie.uid
  const engineToken = params.et || cookie.et || null

  // Store bid and et in cookie for later
  Cookies.set(
    'ce_s',
    {
      bid: ballotId,
      et: engineToken,
      uid: ballotUserId,
    },
    {
      expires: 30,
    },
  )
  const setupCookie = Cookies.get('setupSeen') === 'true'
  store.dispatch(setSetupPreferencesPageSeen(setupCookie))

  // Load values into Redux store
  store.dispatch(
    saveBallotParams(
      ballotId && parseInt(ballotId, 10),
      engineToken,
      ballotUserId,
    ),
  )

  // Tell react-snap how to save Redux state
  window.snapSaveState = () => ({
    __PRELOADED_STATE__: store.getState(),
  })

  return store
}

export default createMystore
