import React from 'react'
import appConfig, { AppConfig } from 'appConfig'
import classNames from 'classnames'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import {
  LocaleSwitcher,
  Logo,
  Markdown,
  Notification,
} from 'components/sparkles'
import AddressModalController from '../AddressModalController/AddressModalController'
import BallotLink from '../BallotLink/BallotLink'
import LeadBar from '../LeadBar/LeadBar'
import localNavContainer from './localNavContainer'
import { Ballot } from 'types'
import styles from './LocalNav.module.scss'
import Hamburger from './Hamburger/Hamburger'
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'

export interface Props extends RouteComponentProps {
  addressUpdateNeedsBallot?: boolean
  alert?: string | null
  backButton?: boolean
  ballot?: Ballot
  center?: boolean
  className?: string
  onAlertClose?: () => void
  onLeadBarClose: () => void
  onLocaleChange: (newLocale: string) => void
  onUpdateAddress?: () => void
  homePath?: string
  isSmallScreen?: boolean
  lead?: any // TODO Type
  logoLinkUrl?: string | null
  logoUrl?: string | null
  showAlert?: boolean
  showLeadBar?: boolean
  sticky?: boolean
  tenantName: string
}

const LocalNav = ({
  addressUpdateNeedsBallot = true,
  alert,
  backButton = false,
  center = false,
  className,
  onAlertClose,
  onLeadBarClose,
  onLocaleChange,
  onUpdateAddress,
  homePath = '/',
  lead,
  location,
  logoLinkUrl,
  logoUrl,
  showAlert = false,
  showLeadBar = false,
  sticky = false,
  tenantName,
}: Props) => {
  const { i18n, t } = useTranslation()

  const globalAlertKey = `alerts:GlobalAlert`
  const globalAlert =
    i18n.exists && i18n.exists(`${globalAlertKey}`) && t(`${globalAlertKey}`)

  const handleLocaleChange = (locale: string) => {
    i18n
      .changeLanguage(locale)
      .then(() => onLocaleChange(locale))
      .catch(err =>
        console.error('An error occurred while switching locales', err),
      )
  }

  return (
    <header className={classNames(styles.LocalNav__container, className)}>
      {showAlert && (
        <Notification
          className={styles.LocalNav__GlobalAlert}
          dismissable
          onClose={onAlertClose}
          variant="warning"
        >
          <Markdown source={alert || globalAlert} />
        </Notification>
      )}

      {showLeadBar && <LeadBar lead={lead} onClose={onLeadBarClose} />}

      <div
        className={classNames(
          styles.LocalNav,
          center && styles.LocalNav__center,
          sticky && styles.LocalNav__sticky,
        )}
      >
        <div className={styles.LocalNav__Left}>
          {backButton && location.pathname !== homePath && (
            <Link
              className={styles.LocalNav__Back}
              to={homePath}
              title={t('LocalNav.back')}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Link>
          )}

          <div className={styles.LocalNav__BrandContainer}>
            {logoLinkUrl ? (
              <a
                className={styles.LocalNav__Brand}
                href={logoLinkUrl}
                title={t('LocalNav.home')}
              >
                <Logo src={logoUrl} alt={tenantName} />
              </a>
            ) : (
              <BallotLink
                className={styles.LocalNav__Brand}
                to={homePath}
                title={t('LocalNav.home')}
              >
                <Logo src={logoUrl} alt={tenantName} />
              </BallotLink>
            )}
          </div>
        </div>

        <div className={styles.LocalNav__Inner}>
          <Hamburger />

          <AddressModalController
            shouldRedirect={false}
            onUpdateAddress={onUpdateAddress}
            needsBallot={addressUpdateNeedsBallot}
            modalTitle={t('LocalNav.updateAddress')}
            openModalClickStreamEvent="From Nav Bar"
          >
            {({ onClick }) => (
              <button
                className={styles.addressBtn}
                onClick={onClick}
                data-cy="update_address_button"
              >
                <FontAwesomeIcon
                  icon={faLocationDot}
                  title={t('LocalNav.updateAddress')}
                />
              </button>
            )}
          </AddressModalController>

          <LocaleSwitcher
            className={styles.localeSwitcher}
            currentLocale={i18n.language}
            locales={(appConfig as AppConfig).supportedLocales}
            onChange={handleLocaleChange}
          />
        </div>
      </div>
    </header>
  )
}

export default localNavContainer(withRouter(LocalNav))
