import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Ballot, Election } from 'types'
import getPathForApp from 'utils/getPathForApp'
import hamburgerContainer from './hamburgerContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons'
import styles from './Hamburger.module.scss'
import classNames from 'classnames'
import { useHistory } from 'react-router'
import useAnalytics from 'hooks/useAnalytics'
import { shouldSeeVoterGuide } from 'utils/shouldSeeVoterGuide'
import { useTranslation } from 'react-i18next'

interface NavLink {
  enabled: boolean
  isActive(path: string): boolean
  title: string
  to: string
}

interface Props {
  ballot: Ballot | null
  election: Election | null
}

const Hamburger: React.FC<Props> = ({ ballot, election }) => {
  const analytics = useAnalytics()
  const { t } = useTranslation()
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)

  const isElectionPublished = shouldSeeVoterGuide(election)

  const links: NavLink[] = [
    {
      isActive: path => path.startsWith(getPathForApp('civic_center')),
      to: getPathForApp('civic_center'),
      title: t('LocalNav.Hamburger.civicCenter'),
      enabled: true,
    },
    {
      isActive: path =>
        path.startsWith(`${getPathForApp('ballot_engine')}setup`),
      to: `${getPathForApp('ballot_engine')}setup`,
      title: t('LocalNav.Hamburger.preferences'),
      enabled: Boolean(ballot) && isElectionPublished,
    },
    {
      isActive: path => {
        const basePath = getPathForApp('ballot_engine')

        if (path.startsWith(`${basePath}setup`)) {
          return false
        }

        return path.startsWith(basePath)
      },
      to: `${getPathForApp('ballot_engine')}review`,
      title: t('LocalNav.Hamburger.ballot'),
      enabled: Boolean(ballot) && isElectionPublished,
    },
  ]

  const handleToggle = React.useCallback(() => {
    const nextValue = !isOpen
    analytics?.track('Nav: hamburger toggled', { open: nextValue })
    setIsOpen(nextValue)
  }, [analytics, isOpen, setIsOpen])

  const trackClick = React.useCallback(
    (to: string) => {
      analytics?.track('Nav: clicked item', { to })
    },
    [analytics],
  )

  return (
    <>
      <button className={styles.button} onClick={handleToggle} title="Menu">
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </button>

      <nav
        className={classNames(styles.nav, {
          [styles.open]: isOpen,
        })}
      >
        {links.map(link => {
          if (!link.enabled) {
            return null
          }

          return (
            <Link
              className={classNames(styles.navItem, {
                [styles.active]: link.isActive(history.location.pathname),
              })}
              onClick={() => trackClick(link.to)}
              to={link.to}
              key={link.to}
            >
              {link.title}
            </Link>
          )
        })}
      </nav>
    </>
  )
}

export default hamburgerContainer(Hamburger)
